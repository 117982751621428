import { CarShopLabels, ShopCar, ShopOfferFOL, ShopOfferPrivateLease } from '../../utilities/car-shop/car-shop-types'
import CarShopPriceTableMatrix from './car-shop-price-table-matrix'
import CarShopPriceTablePurchase from './car-shop-price-table-purchase'
import CarShopPriceTableR2S from './car-shop-price-table-r2s'
import CarShopPriceTableTakeover from './car-shop-price-table-takeover'
import getPrettyOfferTypeName from '../../utilities/car-shop/get-pretty-offer-type-name'
import { LocaleObject } from 'utilities/general/types'
import MaterialSelect from '../ui/material-select'
import { matrixTypes } from '../../utilities/car-shop/get-available-matrix-keys'
import queryString from 'query-string'
import React, { Fragment, useState } from 'react'
import styled, { css } from 'styled-components'

interface CarShopPriceTableProps {
    carShopLabels: CarShopLabels
    locale: LocaleObject
    location: Location
    shopCar: ShopCar
}

const CarShopPriceTable = ({ carShopLabels, locale, location, shopCar }: CarShopPriceTableProps): JSX.Element => {
    const offerTypeDefaultValue = queryString.parse(location.search).offerType || 'CarShop_ShopOfferFOL'

    const [offerType, setOfferType] = useState(offerTypeDefaultValue)

    const availableOfferTypes = shopCar.offers.map(offer => ({
        name: getPrettyOfferTypeName(carShopLabels, offer.__typename),
        value: offer.__typename,
    }))

    return (
        <>
            {shopCar.contractTakeover && <CarShopPriceTableTakeover carShopLabels={carShopLabels} shopCar={shopCar} />}
            {!shopCar.contractTakeover && (
                <Fragment>
                    <MaterialSelect
                        currentValue={offerType as string}
                        label={carShopLabels.priceTableOfferTypeLabel}
                        onChange={value => setOfferType(value)}
                        selectables={availableOfferTypes}
                    />
                    {shopCar.offers.map(offer => {
                        if (matrixTypes.includes(offer.__typename)) {
                            return (
                                <PriceTableContainer key={offer.__typename} isVisible={offerType === offer.__typename}>
                                    <CarShopPriceTableMatrix
                                        carShopLabels={carShopLabels}
                                        offer={offer as ShopOfferFOL | ShopOfferPrivateLease}
                                        shopCar={shopCar}
                                    />
                                </PriceTableContainer>
                            )
                        } else if (offer.__typename === 'CarShop_ShopOfferReady2Share') {
                            return (
                                <PriceTableContainer key={offer.__typename} isVisible={offerType === offer.__typename}>
                                    <CarShopPriceTableR2S
                                        carShopLabels={carShopLabels}
                                        offer={offer}
                                        shopCar={shopCar}
                                    />
                                </PriceTableContainer>
                            )
                        } else if (offer.__typename === 'CarShop_ShopOfferPurchase') {
                            return (
                                <PriceTableContainer key={offer.__typename} isVisible={offerType === offer.__typename}>
                                    <CarShopPriceTablePurchase
                                        carShopLabels={carShopLabels}
                                        offer={offer}
                                        shopCar={shopCar}
                                    />
                                </PriceTableContainer>
                            )
                        }
                    })}
                    <MoreInformationButton
                        href={
                            // locale.hasCheckout
                            //   ? location.pathname + '/checkout'
                            carShopLabels.priceTableButtonUrl + '?occasion_license_plate=' + shopCar.licensePlate
                        }
                        target="_blank"
                    >
                        {locale.hasCheckout ? carShopLabels.checkoutButtonText : carShopLabels.priceTableButtonText}
                    </MoreInformationButton>
                    <AccompanyingText>
                        {locale.hasCheckout
                            ? carShopLabels.checkoutButtonAccompanyingText
                            : carShopLabels.priceTableButtonAccompanyingText}
                    </AccompanyingText>
                </Fragment>
            )}
        </>
    )
}

export default CarShopPriceTable

const PriceTableContainer = styled.div<{ isVisible?: boolean }>`
    display: none;
    min-height: 14rem;
    margin: 1.375rem 0 1.625rem;

    ${props =>
        props.isVisible &&
        css`
            display: flex;
            align-items: center;

            > * {
                width: 100%;
            }
        `}
`

const MoreInformationButton = styled.a`
    display: block;
    background-image: var(--gradient-green);
    color: var(--color-white);
    text-align: center;
    padding: 1rem 2rem;
    border-radius: 3rem;
    transition: opacity 0.1s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
`

const AccompanyingText = styled.p`
    color: var(--color-grey-extra-dark);
    font-size: var(--font-size-small);
    text-align: center;
    line-height: 1.45;
    margin: 1rem 0 0;
`
