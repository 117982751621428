import { getLocaleString } from '../general/locale'
import { LocaleObject } from '../general/types'
import { ShopCar, ShopOfferReady2Share } from './car-shop-types'

interface AvailableReady2ShareKeys {
    businessMileage: string[]
    privateMileage: string[]
}

export default function getAvailableR2sKeys(shopCar: ShopCar, locale: LocaleObject): AvailableReady2ShareKeys {
    const localeString = getLocaleString(locale)
    const ready2ShareOffers = shopCar.offers.find(offer => offer.__typename === 'CarShop_ShopOfferReady2Share')

    const businessMileage = (ready2ShareOffers as ShopOfferReady2Share).prices
        .map(({ businessMileage }) => businessMileage)
        .filter((businessMileage, index, array) => array.indexOf(businessMileage) === index)
        .sort((businessMileageA, businessMileageB) => businessMileageA - businessMileageB)
        .map(businessMileage => Intl.NumberFormat(localeString).format(businessMileage))

    const privateMileage = (ready2ShareOffers as ShopOfferReady2Share).prices
        .map(({ privateMileage }) => privateMileage)
        .filter((privateMileage, index, array) => array.indexOf(privateMileage) === index)
        .sort((privateMileageA, privateMileageB) => privateMileageA - privateMileageB)
        .map(privateMileage => Intl.NumberFormat(localeString).format(privateMileage))

    return { businessMileage, privateMileage }
}
