import { CarShopLabels, ShopCar } from '../../utilities/car-shop/car-shop-types'
import CarShopPriceTable from './car-shop-price-table'
import formatWithCurrencySymbol from '../../utilities/general/format-with-currency-symbol'
import getAvailabilityString from '../../utilities/car-shop/get-availability-string'
import { getLocaleString, Locale } from '../../utilities/general/locale'
import React, { useContext } from 'react'
import styled from 'styled-components'

interface CarShopDetailSpecsProps {
    carShopLabels: CarShopLabels
    location: any
    shopCar: ShopCar
}

const CarShopDetailSpecs = ({ carShopLabels, location, shopCar }: CarShopDetailSpecsProps): JSX.Element => {
    const locale = useContext(Locale)
    const localeString = getLocaleString(locale)
    const dateTimeOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }

    return (
        <Container>
            <TopContainer>
                <Title>
                    <span>{shopCar.brand.name}</span>
                    &nbsp;
                    <span>{shopCar.model.name}</span>
                </Title>
                <Subtitle>{shopCar.edition.name}</Subtitle>
                <SpecsList>
                    {!!shopCar.licensePlate && (
                        <>
                            <SpecTitle>{carShopLabels.licensePlateLabel}</SpecTitle>
                            <SpecData>{shopCar.licensePlate}</SpecData>
                        </>
                    )}
                    <SpecTitle>{carShopLabels.registrationDateLabel}</SpecTitle>
                    <SpecData>
                        {Intl.DateTimeFormat(localeString, dateTimeOptions).format(
                            new Date(shopCar.firstRegistrationDate)
                        )}
                    </SpecData>
                    <SpecTitle>{carShopLabels.availabilityLabel}</SpecTitle>
                    <SpecData>{getAvailabilityString({ carShopLabels, locale, shopCar })}</SpecData>
                    <SpecTitle>{carShopLabels.fiscalValueLabel}</SpecTitle>
                    <SpecData>
                        {formatWithCurrencySymbol(shopCar.fiscalValueIncVAT, localeString, {
                            hardcodeCurrency: 'EUR',
                            displayAsFloat: false,
                        })}
                    </SpecData>
                    <SpecTitle>
                        {carShopLabels.mileageLabel}
                        {!!carShopLabels.mileageLabelOptionalDisclaimer && (
                            <small>{carShopLabels.mileageLabelOptionalDisclaimer}</small>
                        )}
                    </SpecTitle>
                    <SpecData>
                        {Intl.NumberFormat(localeString).format(shopCar.currentMileage)}{' '}
                        {carShopLabels.kilometersAbbreviation}
                    </SpecData>
                </SpecsList>
            </TopContainer>
            {!!shopCar.banner?.label && <Banner>{shopCar.banner.label}</Banner>}
            <BottomContainer>
                <PriceTableTitle>{carShopLabels.priceTableTitle}</PriceTableTitle>
                <CarShopPriceTable
                    carShopLabels={carShopLabels}
                    locale={locale}
                    location={location}
                    shopCar={shopCar}
                />
            </BottomContainer>
        </Container>
    )
}

export default CarShopDetailSpecs

const Container = styled.div`
    background-color: var(--color-white);
`

const TopContainer = styled.div`
    max-width: 40rem;
    padding: 1.5rem 1.5rem 0.625rem;
    margin: 0 auto;

    @media print {
        break-after: page;
    }
`

const Title = styled.h1`
    font-family: var(--font-headings);
    font-size: var(--font-size-extra-large);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    margin: 0;

    span:last-of-type {
        display: block;

        @media (min-width: 26rem) {
            display: inline;
        }
    }
`

const Subtitle = styled.p`
    font-family: var(--font-headings);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    margin: 0;
`

const SpecsList = styled.dl`
    font-size: var(--font-size-small);
    display: grid;
    grid-template-columns: 45% 55%;
    grid-gap: 0.5rem 1rem;

    @media (min-width: 26rem) {
        font-size: var(--font-size);
    }
`

const SpecTitle = styled.dt`
    small {
        display: block;
        color: var(--color-grey-dark);
        font-size: var(--font-size-extra-small);
        line-height: 1.45;
    }
`

const SpecData = styled.dd`
    opacity: 0.6;
    margin: 0;
`

const Banner = styled.p`
    min-height: 3rem;
    width: calc(100% + 0.875rem);
    position: relative;
    background-color: #60b4c2;
    color: var(--color-white);
    font-size: var(--font-size-small);
    line-height: 1.25;
    padding: 0.625rem 0.75rem;
    margin: 0 -0.4375rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        top: -0.4375rem;
        border-style: solid;
    }

    &::before {
        left: 0;
        border-width: 0 0 0.4375rem 0.4375rem;
        border-color: transparent transparent #56a2af;
    }

    &::after {
        right: 0;
        border-width: 0.4375rem 0 0 0.4375rem;
        border-color: transparent transparent transparent #56a2af;
    }
`

const BottomContainer = styled.div`
    max-width: 40rem;
    padding: 1.5rem 1.5rem 2rem;
    margin: 0 auto;

    @media print {
        margin-top: 2rem;
        break-after: page;
    }
`

const PriceTableTitle = styled.h2`
    color: var(--color-black-light);
    font-size: var(--font-size-large);
    margin: 0 0 0.625rem;
`
