import { CarShopLabels, ShopOfferReady2Share, ShopCar } from '../../utilities/car-shop/car-shop-types'
import formatWithCurrencySymbol from '../../utilities/general/format-with-currency-symbol'
import getAvailableR2sKeys from '../../utilities/car-shop/get-available-r2s-keys'
import { getLocaleString, Locale } from '../../utilities/general/locale'
import MaterialSelect from '../ui/material-select'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

interface CarShopPriceTableR2SProps {
    carShopLabels: CarShopLabels
    offer: ShopOfferReady2Share
    shopCar: ShopCar
}

const CarShopPriceTableR2S = ({ carShopLabels, offer, shopCar }: CarShopPriceTableR2SProps): JSX.Element => {
    const locale = useContext(Locale)
    const localeString = getLocaleString(locale)
    const NumberFormatter = Intl.NumberFormat(localeString)

    const { businessMileage, privateMileage } = getAvailableR2sKeys(shopCar, locale)

    const [currentBusinessMileage, setCurrentBusinessMileage] = useState(businessMileage[0])
    const [currentPrivateMileage, setCurrentPrivateMileage] = useState(privateMileage[0])

    const businessMileageSelectables = businessMileage.map(mileage => ({
        name: `${mileage} ${carShopLabels.kilometersAbbreviation}`,
        value: mileage,
    }))

    const privateMileageSelectables = privateMileage.map(mileage => ({
        name: `${mileage} ${carShopLabels.kilometersAbbreviation}`,
        value: mileage,
    }))

    const currentPrice = offer.prices.find(
        price =>
            NumberFormatter.format(price.businessMileage) === currentBusinessMileage &&
            NumberFormatter.format(price.privateMileage) === currentPrivateMileage
    )

    return (
        <Container>
            <MaterialSelect
                currentValue={currentBusinessMileage}
                label={carShopLabels.priceTableBusinessKilometersLabel}
                onChange={value => setCurrentBusinessMileage(value)}
                selectables={businessMileageSelectables}
            />
            <MaterialSelect
                currentValue={currentPrivateMileage}
                label={carShopLabels.priceTablePrivateKilometersLabel}
                onChange={value => setCurrentPrivateMileage(value)}
                selectables={privateMileageSelectables}
            />
            {!!currentPrice && (
                <>
                    <Result>
                        <span>{carShopLabels.priceTableBusinessPriceLabel}</span>
                        <span>
                            {formatWithCurrencySymbol(currentPrice.businessPrice, localeString, {
                                hardcodeCurrency: 'EUR',
                                displayAsFloat: false,
                            })}
                        </span>
                        <span>{carShopLabels.excludingTax}</span>
                    </Result>
                    <Result>
                        <span>{carShopLabels.priceTablePrivatePriceLabel}</span>
                        <span>
                            {formatWithCurrencySymbol(currentPrice.privatePrice, localeString, {
                                hardcodeCurrency: 'EUR',
                                displayAsFloat: false,
                            })}
                        </span>
                        <span>{carShopLabels.includingTax}</span>
                    </Result>
                </>
            )}
        </Container>
    )
}

export default CarShopPriceTableR2S

const Container = styled.div`
    display: grid;
    grid: repeat(3, auto) / repeat(2, calc((100% - 1rem) / 2));
    grid-gap: 1.375rem 1rem;

    > :first-child {
        grid-area: 1 / 1 / span 1 / span 2;
    }

    > :nth-child(2) {
        grid-area: 2 / 1 / span 1 / span 2;
    }

    @media (min-width: 26rem) {
        > :nth-child(1n) {
            grid-area: auto;
        }
    }
`

const Result = styled.p`
    margin: 0;

    span {
        display: block;

        &:first-of-type {
            color: var(--color-black-light);
            font-family: var(--font-headings);
            font-size: var(--font-size-extra-small);
            font-weight: var(--font-weight-bold);
            margin: 0 0 0.125rem;

            @media (min-width: 26rem) {
                font-size: var(--font-size-small);
            }
        }

        &:nth-of-type(2) {
            color: var(--color-green-dark);
            font-family: var(--font-headings);
            font-size: var(--font-size-extra-large);
            font-weight: var(--font-weight-bold);
            line-height: 1;

            @media (min-width: 26rem) {
                font-size: var(--font-size-extra-extra-large);
            }
        }

        &:last-of-type {
            color: var(--color-grey-dark);
            font-size: var(--font-size-extra-small);
        }
    }
`
